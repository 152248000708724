@import '../../theme/variables.css';


.itemTitle {
    background-color: #3880ff;
    margin: 20px;
    padding: 10px;
    color: #fff;
    border-radius: 1rem;
}

.itemContent {
    background-color: #f4f5f8;
}

.itemContent {
    border-radius: .5rem;
    margin-top: 10px;
}

.item {
    justify-content: space-between;
    margin: 10px;
}

.address {
    font-size: small;
    font-weight: bold;
}

.itemContent ion-item {
    --padding-start: 0px;
    --inner-padding-end: 0px;
  }